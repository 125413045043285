<template>
  <div>
    <h4 class="mb-3 section-title">{{ $t("g.Lessons") }}</h4>
    <b-card
      class="explore-lessons-component"
      v-for="(lesson, index) in data.lessons"
      :key="index"
    >
      <b-card-header @click="toggleCollapse(index, lesson.id)">
        {{ lesson.name }}
        <img
          :src="arrowSvg"
          :class="activeCollapse === index ? 'open-arrow' : 'close-arrow'"
          alt="arrow"
        />
      </b-card-header>
      <hr class="divider mx-3" v-if="activeCollapse === index" />
      <b-collapse :visible="activeCollapse === index">
        <b-card-body>
          <b-card-text>
            {{
              lesson.description
                ? lesson.description
                : $t("g.ThereIsNoDescription")
            }}
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BLink,
  BCardText,
  BButton,
  BRow,
  BCol,
  BCardSubTitle,
  BCollapse,
  BCardBody,
  BCardHeader,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BCard,
    BButton,
    BCardText,
    BCardSubTitle,
    BCollapse,
    BCardBody,
    BCardHeader,
  },
  props: ["data"],
  data() {
    return {
      activeCollapse: null,
      isCollapseVisible: false,
      sectionsData: null,
      arrowSvg: require("@/assets/images/icons/arrow.svg"),
    };
  },
  methods: {
    toggleCollapse(index, lessonsId) {
      this.sectionsData = null;
      if (this.activeCollapse === index) {
        this.activeCollapse = null;
      } else {
        this.activeCollapse = index;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
