const dateTimeInFormat = (timestamps) => {
    const months = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
    ];

    const dateObj = new Date(timestamps);
    let date = dateObj.getDate();
    if (date < 10) {
        date = "0" + date;
    }

    const month = months[dateObj.getMonth()];
    const year = dateObj.getFullYear();

    let hours = dateObj.getHours();
    if (hours < 10) {
        hours = "0" + hours;
    }

    let minutes = dateObj.getMinutes();
    if (minutes < 10) {
        minutes = "0" + minutes;
    }

    let seconds = dateObj.getSeconds();
    if (seconds < 10) {
        seconds = "0" + seconds;
    }
    return (
        date +
        " - " +
        month +
        " - " +
        year
    );
};

export function formatDateToCustomString(startDate, endDate) {
    // Step 1: Parse the input date strings into JavaScript Date objects
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
  
    // Step 2: Create a custom format string for the output
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
    // Get the day of the month for both start and end dates
    const startDay = startDateObj.getDate();
    const endDay = endDateObj.getDate();
  
    // Get the month (0-indexed) to match with the months array
    const startMonthIndex = startDateObj.getMonth();
    const endMonthIndex = endDateObj.getMonth();
  
    // Get the month name abbreviation from the months array for both start and end dates
    const startMonthAbbreviation = months[startMonthIndex];
    const endMonthAbbreviation = months[endMonthIndex];
  
    // Combine the day and month abbreviation for both start and end dates to get the final formatted strings
    const formattedStartDate = `${startDay} ${startMonthAbbreviation}`;
    const formattedEndDate = `${endDay} ${endMonthAbbreviation}`;
  
    return  `from ${formattedStartDate} to ${formattedEndDate}` ;
}

export default dateTimeInFormat